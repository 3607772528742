.forum-search {
  margin-bottom: 2em;
}

.forum-search__xs-input,
.forum-search__sm-input {
  flex: 0 0 auto;
  text-align: center;
}
.forum-search__xs-input {
  min-width: 3.5em;
  width: 3.5em;
}
.forum-search__sm-input {
  min-width: 5em;
  width: 5em;
}

.forum-search__filter-type,
.forum-search__filter-term,
.forum-search__add-filter,
.forum-search__filter-include {
  margin-bottom: 0.5rem;
}

.forum-search__add-filter {
  .u-py-xs();
}

.forum-search__page-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
}

.forum-search__active-filters {
  margin: 1.25rem 0;
  padding: 0.6rem 0.75rem;
  border-radius: 0.3rem;
  color: @theme-light;
  background-color: @grey-dark;
}

.forum-search__active-filter {
  .l-flex-row();
  gap: 0.35em;
  margin-bottom: 0.5em;
}

.forum-search__active-filter-info {
  flex: 1 1 auto;
  .alert();
  margin: 0;
  padding: 0.25em 0.75em;
  color: @theme-dark;
}

.forum-search__remove-filter {
  flex: 0 0 auto;
  display: flex;
  padding: 0.5em;
  align-self: flex-start;

  svg {
    margin: auto;
    width: 1em;
    height: 1em;
  }
  svg path {
    fill: @theme-accent;
  }
}

.forum-search__active-filters-head {
  display: flex;
  align-items: baseline;
  padding-bottom: 1rem;

  > p {
    flex: 1 1 auto;
    margin: 0;
  }
}
.forum-search__remove-all-filters {
  flex: 0 0 auto;
  color: @theme-accent;
}



.forum-search__page-button {
  display: flex;
  padding-top: 0.7em;
  padding-bottom: 0.7em;

  svg {
    margin: auto;
    width: 1.3em;
    height: 1.3em;
  }
  svg path {
    fill: @theme-light;
  }
}

.forum-search__controls-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25em;
  margin: 1em 0;
  justify-content: center;
  align-items: baseline;
  
  > * {
    padding: 0 0.5em;
  }
}

.forum-search__controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
  justify-content: center;
  align-items: baseline;
  // margin-bottom: 1em;
}



@media screen and (min-width: 30em) {
  .forum-search__filter-select {
    .l-flex-row();
    align-items: flex-end;
    gap: 1em;
  }

  .forum-search__filter-type {
    flex: 1 1 auto;
  }
  .forum-search__filter-term {
    flex: 3 1 auto;
  }
  .forum-search__add-filter,
  .forum-search__filter-include {
    flex: 0 0 auto;
  }
}
